<script setup></script><template>
   <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M7.00098 8C9.21012 8 11.001 6.20914 11.001 4C11.001 1.79086 9.21012 0 7.00098 0C4.79184 0 3.00098 1.79086 3.00098 4C3.00098 6.20914 4.79184 8 7.00098 8Z" class="  group-[&.active]:fill-[#0516B2]   fill-[#637381]"/>
                           <path d="M7.00098 19.001C10.867 19.001 14.001 17.2101 14.001 15.001C14.001 12.7919 10.867 11.001 7.00098 11.001C3.13498 11.001 0.000976562 12.7919 0.000976562 15.001C0.000976562 17.2101 3.13498 19.001 7.00098 19.001Z"
									class="group-[&.active]:fill-[#0516B2]   fill-[#637381]" />
                           <path
                              d="M19 15C19 16.657 16.964 18 14.479 18C15.211 17.2 15.715 16.195 15.715 15.002C15.715 13.807 15.21 12.802 14.476 12.001C16.962 12 19 13.344 19 15ZM16 4C16.0003 4.48272 15.8841 4.95838 15.6613 5.38661C15.4385 5.81483 15.1157 6.18298 14.7202 6.45978C14.3248 6.73658 13.8683 6.91386 13.3897 6.97657C12.9111 7.03928 12.4244 6.98557 11.971 6.82C12.4595 5.96054 12.7156 4.9886 12.714 4C12.714 2.975 12.444 2.013 11.972 1.181C12.4254 1.01563 12.9119 0.962078 13.3904 1.02489C13.8689 1.0877 14.3252 1.26501 14.7205 1.54179C15.1159 1.81857 15.4386 2.18663 15.6613 2.61475C15.8841 3.04287 16.0002 3.51741 16 4Z"
                              class="group-[&.active]:fill-[#0516B2]   fill-[#637381]" />
                     </svg>


    </template>