<script setup></script><template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_6015_36057)">
  <path opacity="0.2" d="M9.75 4.5H5.25C4.83579 4.5 4.5 4.83579 4.5 5.25V9.75C4.5 10.1642 4.83579 10.5 5.25 10.5H9.75C10.1642 10.5 10.5 10.1642 10.5 9.75V5.25C10.5 4.83579 10.1642 4.5 9.75 4.5Z" fill="white"/>
  <path opacity="0.2" d="M18.75 4.5H14.25C13.8358 4.5 13.5 4.83579 13.5 5.25V9.75C13.5 10.1642 13.8358 10.5 14.25 10.5H18.75C19.1642 10.5 19.5 10.1642 19.5 9.75V5.25C19.5 4.83579 19.1642 4.5 18.75 4.5Z" fill="white"/>
  <path d="M9.75 13.5H5.25C4.83579 13.5 4.5 13.8358 4.5 14.25V18.75C4.5 19.1642 4.83579 19.5 5.25 19.5H9.75C10.1642 19.5 10.5 19.1642 10.5 18.75V14.25C10.5 13.8358 10.1642 13.5 9.75 13.5Z" class="group-[&.active]:stroke-[#0516B2] group-[&.active]:fill-[#0516B2] stroke-[#637381] fill-[#637381]" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path opacity="0.2" d="M18.75 13.5H14.25C13.8358 13.5 13.5 13.8358 13.5 14.25V18.75C13.5 19.1642 13.8358 19.5 14.25 19.5H18.75C19.1642 19.5 19.5 19.1642 19.5 18.75V14.25C19.5 13.8358 19.1642 13.5 18.75 13.5Z" fill="white"/> 
  <path opacity="0.32" d="M18.75 13.5H14.25C13.8358 13.5 13.5 13.8358 13.5 14.25V18.75C13.5 19.1642 13.8358 19.5 14.25 19.5H18.75C19.1642 19.5 19.5 19.1642 19.5 18.75V14.25C19.5 13.8358 19.1642 13.5 18.75 13.5Z" class="group-[&.active]:stroke-[#0516B2] group-[&.active]:fill-[#0516B2] stroke-[#637381] fill-[#637381]"/>
  <path d="M9.75 4.5H5.25C4.83579 4.5 4.5 4.83579 4.5 5.25V9.75C4.5 10.1642 4.83579 10.5 5.25 10.5H9.75C10.1642 10.5 10.5 10.1642 10.5 9.75V5.25C10.5 4.83579 10.1642 4.5 9.75 4.5Z" class="group-[&.active]:stroke-[#0516B2]   stroke-[#637381]  " stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.75 4.5H14.25C13.8358 4.5 13.5 4.83579 13.5 5.25V9.75C13.5 10.1642 13.8358 10.5 14.25 10.5H18.75C19.1642 10.5 19.5 10.1642 19.5 9.75V5.25C19.5 4.83579 19.1642 4.5 18.75 4.5Z" class="group-[&.active]:stroke-[#0516B2] group-[&.active]:fill-[#0516B2] stroke-[#637381] fill-[#637381]"/>
  <path d="M9.75 13.5H5.25C4.83579 13.5 4.5 13.8358 4.5 14.25V18.75C4.5 19.1642 4.83579 19.5 5.25 19.5H9.75C10.1642 19.5 10.5 19.1642 10.5 18.75V14.25C10.5 13.8358 10.1642 13.5 9.75 13.5Z" class="group-[&.active]:stroke-[#0516B2] group-[&.active]:fill-[#0516B2] stroke-[#637381] fill-[#637381]"/>
  </g>
  <defs>
  <clipPath id="clip0_6015_36057">
  <rect width="24" height="24" fill="white"/>
  </clipPath> 
  </defs>
  </svg>
</template>