<script>
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import IconChat from "@/Icons/Chat.vue";
import IconCountries from "@/Icons/Countries.vue";
import IconCompanies from "@/Icons/Companies.vue";
import IconRegions from "@/Icons/Regions.vue";
import IconGroup from "@/Icons/Group.vue";
import IconJourney from "@/Icons/journey-icon.vue";
import IconAgenda from "@/Icons/agenda.vue";
import IconDown from "@/Icons/arrow-down.vue";
import IconCandidates from "@/Icons/candidates.vue";
import IconJobs from "@/Icons/jobs.vue";
import IconNewsFeed from "@/Icons/newsFeed.vue";
import { Head, Link } from "@inertiajs/vue3";
import LiveChat from "@/Layouts/Partials/LiveChat.vue";
import axios from 'axios';
//import ProposeRegister from "../Partials/ProposeRegister.vue";


import { ref } from 'vue'
//import { Dialog, DialogTitle, DialogPanel, DialogDescription } from '@headlessui/vue'

export default {
  components: {
    ApplicationLogo,
    IconChat,
	 IconCompanies,
	 IconCountries,
	 IconRegions,
	   IconGroup,
    IconAgenda,
    IconDown,
    IconCandidates,
    IconJobs,
    IconNewsFeed,
    LiveChat,
    Head,
	 IconJourney,
    Link,
  //  ProposeRegister,
  //  Dialog,
  //  DialogTitle,
  //  DialogPanel,
  //  DialogDescription
  },
  props: {},
  emits: ['close-menu'],
  data() {
    return {
      activeUrl: window.location.pathname,
      showJobs: false,
      unreadMessagesCount: 0,
      css: {
        bull_active: "w-2 h-2 bg-main rounded-full",
        bull: "w-1 h-1 bg-gray-500 rounded-full",
        label_active: "self-stretch text-main text-sm font-semibold leading-snug",
        label: "self-stretch text-gray-500 text-sm font-medium leading-snug",
        bg_active: 'bg-main bg-opacity-10 active',
        bg_inactive: 'bg-white bg-opacity-0 transition-all hover:bg-grey-8',
      },
      isOpen: ref(true),
    };
  },
  computed: {
    authuser() {
      return this.$page.props.auth?.user;
    }
  },
  methods: {
    isJobListUrl(url) {
      return ['/countries', '/companies', '/regions', '/jobs'].some(prefix => url.startsWith(prefix));
    },
    toggleJobs() {
      this.showJobs = !this.showJobs;
    },
    isActive(url) {
      return this.activeUrl === url;
    },
    updateActiveUrl(url) {
      this.$emit('close-menu');
      this.activeUrl = url;
    },
    closeMenu() {
      this.$emit('close-menu');
    },
    loadUnreadMessagesCount() {
      if (this.authuser) {
        axios.get(route('chat.unread-messages'))
          .then((response) => {
              if (response.data.unreadMessagesCount > 0) {
                  this.unreadMessagesCount = response.data.unreadMessagesCount;
              }
          })

          .catch((error) => {
            console.error(error);
          });
      }
    },
    setIsOpen(value) {
        this.isOpen.value = value
    }
  },
  mounted() {
    // Execute this code when the component is mounted
    if (this.isJobListUrl(this.activeUrl)) {
      this.showJobs = true;
    }

    // Load chat count if user is authenticated
    this.loadUnreadMessagesCount();
  }
}
</script>

<template>




    <div class="w-[280px] h-screen px-4 pb-10 bg-white border-r border-gray-400 border-opacity-20 flex-col justify-start items-center inline-flex absolute z-[60]">
        <div class="self-stretch h-[69px] px-4 pt-6 pb-2 items-center flex">
            <div class="w-[168px] pb-[3px] justify-center items-center inline-flex">
                <ApplicationLogo class="max-w-[168px]" />
                <!--<img class="self-stretch grow shrink basis-0" src="https://via.placeholder.com/168x34" /> -->
            </div>
        </div>
        <div class="flex flex-col items-start self-stretch justify-start gap-1 p-2 grow shrink basis-0">
            <div class="inline-flex items-center self-stretch justify-start pt-4 pb-2 pl-3 bg-white rounded-lg">
                <div class="text-gray-400 text-[11px] font-bold uppercase leading-[18px]">
                    {{ $t('Foundation') }}
                </div>
            </div>
            <!--
             FEED ACTIVE: {{ isActive('/feed') }}   <br/>
             CLASS A: {{ isActive('/feed')
                        ? css.bg_active
                        : css.bg_inactive }}<br/>
             CLASS TEXT: {{ isActive('/feed') ? css.label_active : css.label }}
            -->
            <Link
                :href="route('feed')"
                @click.prevent="updateActiveUrl('/feed')"
					 :class="
                    isActive('/feed')
                        ? css.bg_active
                        : css.bg_inactive
                "
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">
                        <IconNewsFeed />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/feed') ? css.label_active : css.label">
                        {{ $t("News feed") }}
                    </div>
                </div>
            </Link>

            <Link
                :href="route('jobs')"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
				:class="
                    isActive('/jobs')
                        ? css.bg_active
                        : css.bg_inactive
                "
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">
                        <!-- Jobs -->
                        <IconJobs />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div
                        :class="
                            isActive('/jobs') ? css.label_active : css.label
                        "
                    >
                        {{ $t("Jobs") }}
                    </div>
                </div>
                <div
                    class="flex items-center justify-center pl-8"
                    @click.prevent="toggleJobs"
                >
                    <div
                        class="relative w-4 h-4 group-[&.active]:rotate-180"

                    >
                        <IconDown />
                    </div>
                </div>
            </Link>

            <!-- Start Jobs -->
            <Link
                :href="route('jobs')"
                @click.prevent="updateActiveUrl('/jobs')"
                v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div :class="isActive('/jobs') ? css.bull_active : css.bull" ></div>
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/jobs') ? css.label_active : css.label">
                        {{ $t("All jobs") }}
                    </div>
                </div>
            </Link>



            <Link
                href="/jobs/applied"
                @click.prevent="updateActiveUrl('/jobs/applied')"
                v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div :class="isActive('/jobs/applied') ? css.bull_active : css.bull"></div>
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/jobs/applied') ? css.label_active : css.label">
                        {{ $t("Applied") }}
                    </div>
                </div>
            </Link>

            <Link
                href="/jobs/saved"
                @click.prevent="updateActiveUrl('/jobs/saved')"
                v-if="showJobs"
                class="inline-flex items-center self-stretch justify-start pl-3 pr-2 transition-all bg-white bg-opacity-0 rounded-lg hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="flex items-center justify-center w-6 h-6">
                        <div
                            :class="
                                isActive('/jobs/saved')
                                    ? css.bull_active
                                    : css.bull
                            "
                        ></div>
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div
                        :class="
                            isActive('/jobs/saved')
                                ? css.label_active
                                : css.label
                        "
                    >
                        {{ $t("Saved") }}
                    </div>
                </div>
            </Link>

            <!-- End Jobs -->


				<Link
				:href="route('job', { name: 'companies' })"
                @click.prevent="updateActiveUrl('/companies')"
                :class="isActive('/companies') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconCompanies />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/companies') ? css.label_active : css.label">
                        {{ $t("Companies") }}
                    </div>
                </div>

            </Link>

			<!-- 	<Link
				:href="route('job', { name: 'countries' })"
                @click.prevent="updateActiveUrl('/countries')"
                :class="isActive('/countries') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative items-center justify-center w-6 h-6">
                        <IconCountries />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/countries') ? css.label_active : css.label">
                        {{ $t("Countries") }}
                    </div>
                </div>

            </Link> -->

			<Link
				:href="route('job', { name: 'regions' })"
                @click.prevent="updateActiveUrl('/regions')"
                :class="isActive('/regions') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconRegions />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/regions') ? css.label_active : css.label">
                        {{ $t("Regions") }}
                    </div>
                </div>

            </Link>
            <Link
                :href="route('chat.index')"
                @click.prevent="updateActiveUrl('/chat')"
                :class="isActive('/chat') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">
                        <IconChat />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/chat') ? css.label_active : css.label">
                        {{ $t("Chat") }}
                    </div>
                </div>
                <div v-if="unreadMessagesCount"
                    class="w-fit px-1.5 py-1 bg-blue-800 bg-opacity-20 rounded-md justify-start items-center gap-1.5 flex">
                    <div class="text-center text-blue-800 text-xs font-bold font-[Montserrat] leading-tight">
                        {{ unreadMessagesCount }}
                    </div>
                </div>
            </Link>

            <Link :href="route('events.index', 'user')" @click.prevent="updateActiveUrl('/events/user')"
                :class="isActive('/events/user') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-none rounded-lg group">
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">
                        <IconAgenda />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/events/user') ? css.label_active : css.label">{{ $t('Events') }}</div>
                </div>
            </Link>

            <!-- <Link
                :href="route('agendas.index', 'candidate')"
                @click.prevent="updateActiveUrl('/agenda/candidate')"
                :class="isActive('/agenda/candidate') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">
                        <IconAgenda />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/agenda/candidate') ? css.label_active : css.label">
                        {{ $t("Agenda") }}
                    </div>
                </div>
            </Link> -->

            <!-- <Link
                :href="route('group.index')"
                @click.prevent="updateActiveUrl('/group')"
                :class="isActive('/group') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4">
                    <div class="relative w-6 h-6">

							<IconGroup />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/groups') ? css.label_active : css.label">
                       <span class=" group-[&.active]:!text-main group-[&.active]:font-semibold"> {{ $t("Groups") }}</span>
                    </div>
                </div>
            </Link> -->

        <!-- </div>
		  <div class="flex flex-col items-start self-stretch justify-start gap-1 p-2 grow shrink basis-0"> -->
            <div class="inline-flex items-center self-stretch justify-start pt-4 pb-2 pl-3 bg-white rounded-lg">
                <div class="text-gray-400 text-[11px] font-bold uppercase leading-[18px]">
                    {{ $t('My skills') }}
                </div>
            </div>

            <Link
                href="/journey"
                @click.prevent="updateActiveUrl('/journey')"
                :class="isActive('/journey') ? css.bg_active : css.bg_inactive"
                class="inline-flex items-center self-stretch justify-start p-2 pl-3 pr-2 transition-all rounded-lg group/button min-h-11 hover:bg-grey-8 group"
            >
                <div class="flex items-center justify-center pr-4 ">
                    <div class="relative flex items-center justify-center w-6 h-6">
                        <IconJourney />
                    </div>
                </div>
                <div class="inline-flex flex-col items-center justify-center pr-4 grow shrink basis-0">
                    <div :class="isActive('/journey') ? css.label_active : css.label">
                        {{ $t("Career Journey") }}
                    </div>
                </div>
            </Link>


        </div>
    </div>
    <LiveChat></LiveChat>
</template>
